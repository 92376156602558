<template>
    <v-dialog v-model="value" persistent width="600">
        <v-card>
            <v-card-title>
                <span class="text-h6">{{ $t('info.diary.student_jounal_link') }}</span>
                
                <v-spacer></v-spacer>
                <v-btn icon @click="show = false"
                    class="normal-case">
                    <v-icon>mdi-close-thick</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-subtitle class="mt-4 text-body-1 text-center" color="grey darken-2">
                <a class="my-auto" :href="mapLink" target="_blank">{{ mapLink }}</a>
                <v-btn class="ma-2" icon @click="copyCode" :title="copied ? 'Copied!' : 'Copy link'">
                    <v-icon>{{ copied ? 'mdi-check' : 'mdi-content-copy' }}</v-icon>
                </v-btn>
            </v-card-subtitle>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "studentLinkDialog",
    components: {},
    props: {
        value: Boolean,
        mapLink: String,
    },
    data() {
        return {
            copied: false,
        };
    },
    async created() {
        this.value = false;
    },
    watch: {},
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    mounted() {},
    methods: {
        async copyCode() {
            try {
                await navigator.clipboard.writeText(this.mapLink);
                // this.$toastr.Add({
                //     msg: this.$t('invite_page.copied'),
                //     clickClose: true,
                //     timeout: 3000,
                //     position: "toast-top-right",
                //     classNames: ["animated", "bounceInRight"],
                // });
                this.copied = true;
                setTimeout(() => {
                    this.copied = false;
                }, 2000);
            } catch (err) {
                console.error("Unable to copy text", err);
            }
        },
    },
};
</script>
<style scoped></style>