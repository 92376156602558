<template>
    <v-container fluid>
        <v-row class="mr-5">
            <v-btn class="normal-case"
                text color="grey" @click="$router.go(-1)">
                <v-icon>mdi-arrow-left-thick</v-icon>
                {{ $t('quest.edit.back_to_sample') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="deep-orange darken-3" small class="white--text normal-case"
                @click="addSampleQuest">
                {{ $t('button.save') }}
            </v-btn>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-card outlined class="card-no-outline">
            <v-card-title class="text-h6">
                {{ $t('quest.edit.basic_setting') }}
            </v-card-title>
            <v-card outlined>
                <v-card-text>
                    {{ $t('quest.edit.question_title') }}
                    <v-text-field outlined v-model="title"></v-text-field>
                </v-card-text>
                <v-card-text>
                    {{ $t('quest.edit.question_description') }}
                    <v-textarea
                        outlined
                        counter="200"
                        :rules="descriptionRules"
                        v-model="description"></v-textarea>
                </v-card-text>
            </v-card>
            <draggable :list="items" group="people" @start="drag = true" @end="drag = false">
                <v-card outlined v-for="(item, id) in items" :key="id"
                    class="my-2 mx-4 pa-4">
                    <v-row>
                        <v-card-subtitle>
                            <v-icon x-large>mdi-image</v-icon>
                            {{ item.typeName }}
                        </v-card-subtitle>
                        <v-spacer></v-spacer>
                        <v-card-subtitle>
                            <v-icon @click="edit(item)"
                                class="mr-2">mdi-pencil</v-icon>
                            <v-icon @click="openDeleteItemDialog(id)"
                                color="error">mdi-delete</v-icon>
                        </v-card-subtitle>
                    </v-row>
                    <v-card-subtitle class="text-h6">
                        {{ item.title }}
                    </v-card-subtitle>
                    <v-card-subtitle class="text-body1">
                        <v-chip-group disabled v-if="item.type != 'behavior'">
                            <v-chip v-for="(sample, index) in item.sample" :key="index"
                                outlined label color="black" disabled :ripple="false">
                                {{ sample }}
                            </v-chip>
                        </v-chip-group>
                        <v-chip-group v-else disabled column>
                            <v-chip v-for="(sample, index) in item.sample" :key="index"
                                outlined label color="black" disabled :ripple="false">
                                {{ sample.text[0] }}
                            </v-chip>
                        </v-chip-group>
                    </v-card-subtitle>
                </v-card>
            </draggable>
            <v-card-actions>
                <v-btn color="grey darken-3" block outlined
                    @click="add" class="normal-case">
                    <v-icon>mdi-plus</v-icon>
                    {{ $t('quest.edit.new') }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="chooseTypeDialog" width="500">
            <v-card fluid>
                <v-card-title>
                    {{ $t('quest.edit.select_type') }}
                    <v-spacer></v-spacer>
                    <v-btn icon @click="chooseTypeDialog = false" class="normal-case">
                        <v-icon>mdi-close-thick</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider class="my-3"></v-divider>
                <v-card-actions class="d-flex">
                    <v-item-group class="flex-grow-1">
                        <v-row no-gutters v-for="item in types" :key="item.id"
                            class="outline fill-width my-2 cursor-pointer"
                            @click="selectType(item)">
                            <v-col cols="2" class="my-auto text-center">
                                <v-icon x-large>mdi-image</v-icon>
                            </v-col>
                            <v-col cols="10">
                                <v-row>
                                    <v-card-title class="text-h6 font-weight-bold 
                            mb-0 pb-0" color="#4F4F4F">
                                        {{ item.title }}
                                    </v-card-title>
                                </v-row>
                                <v-row>
                                    <v-card-subtitle class="mt-0 pt-0" color="#4F4F4F">
                                        {{ item.description }}
                                    </v-card-subtitle>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-item-group>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="alert" width="400">
            <v-card class="pt-3">
                <v-card-text class="text-h6 text-center">
                    {{ alertMessage }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 normal-case"
                        @click="alert = false">
                        OK
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteFrameAlert" width="400">
            <v-card class="pt-3">
                <v-card-text class="text-h6 text-center">
                    {{ $t('quest.edit_frame.delete_frame') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 normal-case"
                        @click="deleteFrameAlert = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn class="mb-2 normal-case" color="error"
                        @click="deleteItem">
                        {{ $t('button.confirm') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <QuestEditFrame v-model="showQuestEditFrame" :type="selectedType"
            :title="frameTitle" :description="frameDescription"
            :samples="frameSamples" @return-data="getReturnData"></QuestEditFrame>
    </v-container>
</template>

<script>
import QuestEditFrame from '@/components/console/diary/component/QuestEditFrame.vue';
import { diaryServices } from "@/services/diary.js";
import draggable from 'vuedraggable'

export default {
    name: 'QuestEdit',
    components: { QuestEditFrame, draggable },
    data() {
        return {
            homeworkId: null,
            title: '',
            titleRules: [v => !!v || this.$t('quest.edit_frame.rule_label')],
            description: '',
            descriptionRules: [v => !!v || this.$t('quest.edit_frame.rule_description'),
            v => (v && v.length <= 200) || 'Max 200 characters'],
            chooseTypeDialog: false,
            types: [
                {
                    id: 1,
                    title: this.$t('quest.main.event_frame'),
                    description: this.$t('quest.edit.event_frame_description'),
                },
                {
                    id: 2,
                    title: this.$t('quest.main.story_frame'),
                    description: this.$t('quest.edit.story_frame_description'),
                },
                {
                    id: 3,
                    title: this.$t('quest.main.behavior_frame'),
                    description: this.$t('quest.edit.behavior_frame_description'),
                },
            ],
            showQuestEditFrame: false,
            selectedType: '',
            frameTitle: '',
            frameDescription: '',
            frameSamples: [],
            items: [],
            questId: null,
            alert: false,
            alertMessage: '',
            editingItem: null,
            indexToDelete: null,
            deleteFrameAlert: false,
            lang: '',
        }
    },
    async created() {
        this.homeworkId = this.$route.params.id;
        this.questId = this.$route.query.questId;
        this.lang = this.$route.query.lang;
        if (!this.lang) {
            this.lang = localStorage.getItem('locale');
        }
        if (this.questId) {
            const result = await diaryServices.getSampleQuestDetail(this.homeworkId, this.questId);
            this.title = result.data.title;
            this.description = result.data.description;
            let i = 1;
            for (let item of result.data.sample_list) {
                let sampleList;
                if (item.type != 'behavior') {
                    if (item.sample && item.sample.length > 0) {
                        sampleList = item.sample[0].text;
                    }
                } else {
                    sampleList = [];
                    for (let value of item.sample) {
                        sampleList.push({
                            id: value.id,
                            text: value.text,
                        });
                    }
                }
                const typeId = this.getTypeId(item.type);
                this.items.push({
                    id: i++,
                    title: item.title,
                    sample: sampleList,
                    type: item.type,
                    typeName: this.$t(`quest.main.${item.type}_frame`),
                    order: item.order,
                    typeId: typeId,
                    contentId: item.id,
                });
            }
            this.items = this.items.sort((a, b) => a.order - b.order);
        }
    },
    methods: {
        getTypeId(type) {
            switch (type) {
                case 'event':
                    return 1;
                case 'story':
                    return 2;
                case 'behavior':
                    return 3;
            }
        },
        getType(typeId) {
            switch (typeId) {
                case 1:
                    return 'event';
                case 2:
                    return 'story';
                case 3:
                    return 'behavior';
            }
        },
        add() {
            this.chooseTypeDialog = true;
        },
        edit(item) {
            this.selectedType = item.typeId;
            this.frameTitle = item.title;
            this.frameSamples = item.sample;
            this.editingItem = item;
            this.showQuestEditFrame = true;
        },
        openDeleteItemDialog(index) {
            this.indexToDelete = index;
            this.deleteFrameAlert = true;
        },
        deleteItem() {
            this.items.splice(this.indexToDelete, 1);
            this.indexToDelete = null;
            this.deleteFrameAlert = false;
        },
        selectType(item) {
            this.selectedType = item.id;
            this.chooseTypeDialog = false;
            this.showQuestEditFrame = true;
        },
        async addSampleQuest() {
            //Check if all fields are filled
            let canAddSampleQuest = true;
            this.alertMessage = '';
            if (this.title === '') {
                canAddSampleQuest = false;
                this.alertMessage = this.$t('quest.edit.alert_message.title');
            }
            if (this.description === '') {
                canAddSampleQuest = false;
                if (this.alertMessage.length > 0) {
                    this.alertMessage += '、';
                } else {
                    this.alertMessage = this.$t('quest.edit.alert_message.add');
                }
                this.alertMessage += this.$t('quest.edit.alert_message.description');
            }
            if (this.items.length < 3) {
                canAddSampleQuest = false;
                if (this.alertMessage.length > 0) {
                    this.alertMessage += '、';
                } else {
                    this.alertMessage = this.$t('quest.edit.alert_message.add');
                }
                this.alertMessage += this.$t('quest.edit.alert_message.need_more_frame');
            }
            if (canAddSampleQuest == false) {
                this.alert = true;
                return;
            }
            let filteredItems = [];
            this.items.forEach((oriItem, index) => {
                if (oriItem.typeId == 3) {
                    let sampleList = [];
                    if (oriItem.sample && oriItem.sample.length > 0) {
                        for (let behaviorSample of oriItem.sample) {
                            if (behaviorSample.text.length > 0) {
                                sampleList.push({
                                    id: behaviorSample.id,
                                    text: behaviorSample.text,
                                });
                            }
                        }
                    }
                    filteredItems.push({
                        id: oriItem.id,
                        title: oriItem.title,
                        sample: sampleList,
                        type: oriItem.type,
                        typeName: oriItem.typeName,
                        order: index + 1,
                        contentId: oriItem.contentId,
                    });
                } else {
                    oriItem.order = index + 1;
                    filteredItems.push(oriItem);
                }
            });
            const payload = {
                quest_id: this.questId,
                quest_title: this.title,
                quest_description: this.description,
                sample_list: filteredItems,
                lang: this.lang,
            }
            await diaryServices.addSampleQuest(this.homeworkId, payload);
            this.$router.go(-1);
        },
        async addSampleQuestArchive() {
            let eventSample = `[]`;
            if (this.items.eventItem.sample.length > 0) {
                eventSample = `[{"id":"event","text":${JSON.stringify(this.items.eventItem.sample)}}]`;
            }
            let storySample = `[]`;
            if (this.items.storyItem.sample.length > 0) {
                storySample = `[{"id":"story","text":${JSON.stringify(this.items.storyItem.sample)}}]`;
            }
            let behaviorSample = `[]`;
            if (this.items.behaviorItem.sample.length > 0) {
                let sampleList = [];
                for (let value of this.items.behaviorItem.sample) {
                    sampleList.push({
                        id: value.id,
                        text: value.text,
                    });
                }
                behaviorSample = JSON.stringify(sampleList);
            }
            //Check if all fields are filled
            let canAddSampleQuest = true;
            this.alertMessage = '';
            if (this.title === '') {
                canAddSampleQuest = false;
                this.alertMessage = this.$t('quest.edit.alert_message.title');
            }
            if (this.description === '') {
                canAddSampleQuest = false;
                if (this.alertMessage.length > 0) {
                    this.alertMessage += '、';
                }
                this.alertMessage += this.$t('quest.edit.alert_message.description');
            }
            if (this.items.eventItem.title === '') {
                canAddSampleQuest = false;
                if (this.alertMessage.length > 0) {
                    this.alertMessage += '、';
                } else {
                    this.alertMessage = this.$t('quest.edit.alert_message.add');
                }
                this.alertMessage += this.$t('quest.main.event_frame');
            }
            if (this.items.storyItem.title === '') {
                canAddSampleQuest = false;
                if (this.alertMessage.length > 0) {
                    this.alertMessage += '、';
                } else {
                    this.alertMessage = this.$t('quest.edit.alert_message.add');
                }
                this.alertMessage += this.$t('quest.main.story_frame');
            }
            if (this.items.behaviorItem.title === '') {
                canAddSampleQuest = false;
                if (this.alertMessage.length > 0) {
                    this.alertMessage += '、';
                } else {
                    this.alertMessage = this.$t('quest.edit.alert_message.add');
                }
                this.alertMessage += this.$t('quest.main.behavior_frame');
            }

            if (canAddSampleQuest == false) {
                this.alert = true;
                return;
            }
            const payload = {
                quest_id: this.questId,
                quest_title: this.title,
                quest_description: this.description,
                event_title: this.items.eventItem.title,
                story_title: this.items.storyItem.title,
                behavior_title: this.items.behaviorItem.title,
                event_sample: eventSample,
                story_sample: storySample,
                behavior_sample: behaviorSample,
            }
            await diaryServices.addSampleQuest(this.homeworkId, payload);
            this.$router.go(-1);
        },
        getReturnData(item) {
            if (this.editingItem) {
                this.editingItem.title = item.title;
                if (item.type == 3) {
                    this.editingItem.sample = [];
                    for (let behaviorSample of item.samples) {
                        if (behaviorSample.text.length > 0) {
                            this.editingItem.sample.push({
                                id: behaviorSample.id,
                                text: behaviorSample.text,
                            });
                        }
                    }
                } else {
                    this.editingItem.sample = item.samples;
                }
            } else {
                if (item.type == 3) {
                    let samples = [];
                    for (let behaviorSample of item.samples) {
                        if (behaviorSample.text.length > 0) {
                            samples.push({
                                id: behaviorSample.id,
                                text: behaviorSample.text,
                            });
                        }
                    }
                    this.items.push({
                        id: this.items.length + 1,
                        title: item.title,
                        sample: samples,
                        type: this.getType(item.type),
                        typeName: item.typeName,
                        order: this.items.length + 1,
                        typeId: item.type,
                    });
                } else {
                    this.items.push({
                        id: this.items.length + 1,
                        title: item.title,
                        sample: item.samples,
                        type: this.getType(item.type),
                        typeName: item.typeName,
                        order: this.items.length + 1,
                        typeId: item.type,
                    });
                }
            }
            this.editingItem = null;
            this.frameTitle = '';
            this.frameSamples = [];
            this.showQuestEditFrame = false;
        },
    },
}

</script>

<style scoped>
.card-no-outline {
    border: none;
}

.outline {
    border: 1px solid #E0E0E0;
    border-radius: 10px;
}
</style>