<template>
    <div>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-3" large></v-breadcrumbs>
        <div class="main-content mx-auto">
            <v-form ref="homeworkForm" v-model="valid" lazy-validation>
                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.diary_name') }}</label>
                    <v-text-field v-model="nameInput" :label="$t('edit.diary_name_label')" outlined flat solo dense
                        hide-details="auto"
                        :rules="nameInputRules"></v-text-field>
                </div>
                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.diary_description') }}</label>
                    <v-textarea v-model="descriptionInput" :label="$t('edit.diary_description_label')" outlined flat
                        solo
                        hide-details="auto"
                        :rules="descriptionInputRules"></v-textarea>
                </div>
                <!-- <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.select_grade') }}</label>
                    <v-row no-gutters>
                        <v-col cols="4" md="2" v-for="(item, ind) in gradeList" :key="`grade${ind}`">
                            <v-checkbox class="mt-0" v-model="gradeInput" :label="item.title" :value="item.value"
                                hide-details="auto" :rules="gradeInputRules"></v-checkbox>
                        </v-col>
                    </v-row>
                </div> -->

                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.user_limit') }}</label><small>（{{
                        $t('user_charge_plan.ticket_remain_num') }}{{ ucpCount }} ）</small>
                    <v-text-field v-model="studentLimitInput" :label="$t('edit.user_limit')" outlined flat solo dense
                        hide-details="auto"
                        type="number"
                        :max="ucpCount"
                        :min="currentTookNumber"
                        :rules="studentLimitInputRules"></v-text-field>
                </div>

                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.label_color') }}</label>
                    <v-item-group class="d-flex" mandatory v-model="colorInput">
                        <v-item class="mr-2" v-for="item in colorList" :key="item.value" :value="item.value"
                            v-slot="{ active, toggle }">
                            <v-card :color="item.code" class="d-flex align-center text-center" dark height="30"
                                width="30"
                                @click="toggle">
                                <v-scroll-y-transition>
                                    <v-icon v-if="active" class="mx-auto" label>mdi-check</v-icon>
                                </v-scroll-y-transition>
                            </v-card>
                        </v-item>
                    </v-item-group>
                </div>

                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit_pronunciation') }}</label>
                    <v-select :items="speechLangList" item-text="title" item-value="value" v-model="speechLangInput"
                        :label="$t('edit.lang.users_language')" outlined flat dense solo hide-details="auto"
                        :rules="speechLangInputRules"></v-select>
                </div>

                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.expriation') }}</label>
                    <v-radio-group class="mt-0" dense hide-details="auto" v-model="diaryTimeTypeInput">
                        <v-radio v-for="item in diaryTimeTypeList" :key="item.value" :label="item.title"
                            :value="item.value"
                            hide-details="auto"></v-radio>
                    </v-radio-group>
                    <v-card class="mt-2 px-3 pt-5" outlined v-if="diaryTimeTypeInput == '2'">
                        <v-row>
                            <v-col>
                                <v-menu ref="datePickerMenu" v-model="datePickerMenu" :close-on-content-click="false"
                                    :return-value.sync="takeEndDateInput" transition="scale-transition" offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="takeEndDateInput" :label="$t('edit.select_date')"
                                            prepend-icon="mdi-calendar"
                                            readonly v-bind="attrs" v-on="on" dense
                                            :rules="takeEndDateInputRules"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="takeEndDateInput" :min="diaryTimeMinDate" no-title
                                        scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn class="normal-case" text color="primary" @click="datePickerMenu = false">
                                            {{ $t('button.cancel') }}
                                        </v-btn>
                                        <v-btn class="normal-case" text color="primary"
                                            @click="$refs.datePickerMenu.save(takeEndDateInput)">
                                            {{ $t('button.confirm') }}
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu ref="timePickerMenu" v-model="timePickerMenu" :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="takeEndTimeInput" transition="scale-transition" offset-y
                                    max-width="290px"
                                    min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="takeEndTimeInput" label="選擇時間"
                                            prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                                            dense :rules="takeEndTimeInputRules"></v-text-field>
                                    </template>
                                    <v-time-picker v-if="timePickerMenu" v-model="takeEndTimeInput" format="24hr">
                                        <v-spacer></v-spacer>
                                        <v-btn class="normal-case" text color="primary" @click="timePickerMenu = false">
                                            {{ $t('button.cancel') }}
                                        </v-btn>
                                        <v-btn class="normal-case" text color="primary"
                                            @click="$refs.timePickerMenu.save(takeEndTimeInput)">
                                            {{ $t('button.confirm') }}
                                        </v-btn>
                                    </v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
                <div v-if="shareTypeAvailable" class="mb-5">
                    <label class="font-weight-medium">{{ $t('button.setting_apply') }}</label>
                    <v-radio-group class="mt-0" dense hide-details="auto" v-model="diaryShareTypeInput">
                        <v-radio v-for="item in diaryShareTypeList" :key="item.value" :label="item.title"
                            :value="item.value"
                            hide-details="auto"></v-radio>
                    </v-radio-group>
                </div>

                <div v-if="showQuestOnlyOptions">
                    <div class="mb-3">
                        <label class="font-weight-medium">{{ $t('edit.setting_calendar') }}</label>
                        <v-radio-group class="mt-0" dense hide-details="auto" v-model="diaryCalendarTypeInput">
                            <v-radio v-for="item in diaryCalendarTypeList" :key="item.value" :label="item.title"
                                :value="item.value"
                                hide-details="auto"></v-radio>
                        </v-radio-group>
                    </div>
                    <!-- <div class="mb-3">
                        <v-checkbox class="my-2 font-weight-medium" v-model="aiScoring" hide-details="auto"
                            :label="$t('edit.ai_scoring')" disabled></v-checkbox>
                    </div> -->
                </div>
                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('button.notification_setting') }}</label>
                    <div class="mb-1">
                        <v-checkbox class="mt-0" v-model="diaryNotificationSubmitInput"
                            :label="$t('edit.notify_submit')"
                            hide-details="auto"></v-checkbox>
                        <v-select :items="diaryNotificationSubmitList" item-text="title" item-value="value"
                            v-model="diaryNotificationSubmitTimeInput" :label="$t('edit.notify_time')" outlined flat
                            dense solo
                            hide-details="auto" :disabled="!diaryNotificationSubmitInput"
                            :rules="diaryNotificationSubmitTimeInputRules"></v-select>
                    </div>
                    <div class="mb-1">
                        <v-checkbox class="mt-0" v-model="diaryNotificationStatInput"
                            :label="$t('edit.notify_emotion_report')"
                            hide-details="auto"></v-checkbox>
                        <v-select :items="diaryNotificationStatList" item-text="title" item-value="value"
                            v-model="diaryNotificationStatTimeInput" :label="$t('edit.notify_time')" outlined flat dense
                            solo
                            hide-details="auto" :disabled="!diaryNotificationStatInput"
                            :rules="diaryNotificationStatTimeInputRules"></v-select>
                    </div>
                    <div class="mb-1">
                        <v-checkbox class="mt-0" v-model="diaryNotificationExtremeWordInput"
                            :label="$t('edit.notify_violence_detect')"
                            hide-details="auto" disabled></v-checkbox>
                        <v-select :items="diaryNotificationExtremeWordList" item-text="title" item-value="value"
                            v-model="diaryNotificationExtremeWordTimeInput" :label="$t('edit.notify_time')" outlined
                            flat dense solo
                            hide-details="auto" :disabled="!diaryNotificationExtremeWordInput"
                            :rules="diaryNotificationExtremeWordTimeInputRules"></v-select>
                    </div>
                </div>
            </v-form>
            <v-divider></v-divider>
            <div class="text-right mt-4">
                <v-btn v-if="homeworkId == ''" class="mr-2 normal-case" color="secondary" outlined large
                    @click="$router.go(-1)">
                    {{ $t('button.cancel') }}
                </v-btn>
                <v-btn v-else class="mr-2 normal-case" color="secondary" outlined large
                    @click="$router.push('/diary/homework/:id/info'.replace(':id', homeworkId))">
                    {{ $t('button.cancelAndBackToInfo') }}
                </v-btn>
                <v-btn class="normal-case" color="primary" large :loading="buttonLoading" :disabled="buttonLoading"
                    @click="saveHomework">{{
                        $t('button.confirm') }}
                </v-btn>
            </div>
        </div>
        <v-snackbar v-model="snackBar.on" :timeout="snackBar.timeout" :color="snackBar.type" right>
            {{ snackBar.text }}
        </v-snackbar>
    </div>
</template>

<script>
import { diaryServices } from '@/services/diary.js';
import { userPayServices } from '@/services/userPay.js';
export default {
    name: "news",
    components: {},
    data() {
        return {
            snackBar: {
                on: false,
                text: "My timeout is set to 2000.",
                timeout: 2000,
                type: "info",
            },
            breadcrumbsItems: [],
            buttonLoading: false,
            homeworkId: '',
            nameInput: '',
            nameInputRules: [(v) => !!v || this.$t('general.msg.must_filled')],
            descriptionInput: '',
            descriptionInputRules: [(v) => !!v || this.$t('general.msg.must_filled')],
            gradeInput: [],
            gradeInputRules: [() => this.gradeInput.length != 0 || this.$t('general.msg.must_filled')],
            gradeList: [
                {
                    title: this.$t('edit.grade.1'),
                    value: '1',
                }, {
                    title: this.$t('edit.grade.2'),
                    value: '2',
                }, {
                    title: this.$t('edit.grade.3'),
                    value: '3',
                }, {
                    title: this.$t('edit.grade.4'),
                    value: '4',
                }, {
                    title: this.$t('edit.grade.5'),
                    value: '5',
                }, {
                    title: this.$t('edit.grade.6'),
                    value: '6',
                }, {
                    title: this.$t('edit.grade.7'),
                    value: '7',
                }, {
                    title: this.$t('edit.grade.8'),
                    value: '8',
                }, {
                    title: this.$t('edit.grade.9'),
                    value: '9',
                }
            ],
            studentLimitInput: '',
            studentLimitInputRules: [
                (v) => !!v || this.$t('general.msg.must_filled'),
                (v) => v <= (this.ucpCount + this.currentPublishNumber) || this.$t('edit.rules.ucp_insufficent'),
                (v) => v >= 1 || `${this.$t('edit.rules.min_number')} 1`,
                (v) => v >= this.currentTookNumber || `${this.$t('edit.rules.took_number')} ${this.currentTookNumber}`,
            ],
            studentLimitList: [
                10, 20, 30, 40, 50, 60, 70, 80
            ],
            colorInput: '',
            colorList: [
                {
                    code: 'red',
                    value: '1',
                }, {
                    code: 'orange',
                    value: '2',
                }, {
                    code: 'yellow darken-1',
                    value: '3',
                }, {
                    code: 'green',
                    value: '4',
                }, {
                    code: 'blue',
                    value: '5',
                }, {
                    code: 'blue darken-4',
                    value: '6',
                }, {
                    code: 'deep-purple ',
                    value: '7',
                }
            ],
            speechLangInput: 'zh',
            speechLangInputRules: [(v) => !!v || this.$t('general.msg.must_filled')],
            speechLangList: [
                // {
                //     title: this.$t('edit.lang.users_language'),
                //     value: 'user'
                // },
                {
                    title: this.$t('edit.lang.zh'),
                    value: 'zh'
                }, {
                    title: this.$t('edit.lang.en'),
                    value: 'en'
                }
            ],
            diaryTimeTypeInput: '1',
            diaryTimeTypeList: [{
                title: this.$t('edit.expire_unlimit'),
                value: '1',
            }, {
                title: this.$t('edit.expire_at'),
                value: '2',
            }],
            diaryTimeMinDate: null,
            takeEndDateInput: '',
            takeEndDateInputRules: [(v) => (this.diaryTimeTypeInput == '2' && !!v) || this.$t('general.msg.must_filled')],
            takeEndTimeInput: '',
            takeEndTimeInputRules: [(v) => (this.diaryTimeTypeInput == '2' && !!v) || this.$t('general.msg.must_filled')],
            datePickerMenu: false,
            timePickerMenu: false,
            diaryShareTypeInput: '2',
            diaryShareTypeList: [{
                title: this.$t('edit.submit_auto'),
                value: '2',
            }, {
                title: this.$t('edit.submit_click'),
                value: '3',
            }],
            diaryNotificationSubmitInput: false,
            diaryNotificationSubmitTimeInput: null,
            diaryNotificationSubmitTimeInputRules: [(v) => ((this.diaryNotificationSubmitInput && v == null) ? this.$t('general.msg.must_filled') : true)],
            diaryNotificationSubmitList: [
                {
                    title: this.$t('edit.notify_at.immediately'),
                    value: '-1',
                }, {
                    title: this.$t('edit.notify_at.8'),
                    value: '8',
                }, {
                    title: this.$t('edit.notify_at.12'),
                    value: '12',
                }, {
                    title: this.$t('edit.notify_at.17'),
                    value: '17',
                }, {
                    title: this.$t('edit.notify_at.21'),
                    value: '21',
                }
            ],
            diaryNotificationStatInput: false,
            diaryNotificationStatTimeInput: null,
            diaryNotificationStatTimeInputRules: [(v) => ((this.diaryNotificationStatInput && v == null) ? this.$t('general.msg.must_filled') : true)],
            diaryNotificationStatList: [
                {
                    title: this.$t('edit.notify_at.8'),
                    value: '8',
                }, {
                    title: this.$t('edit.notify_at.12'),
                    value: '12',
                }, {
                    title: this.$t('edit.notify_at.17'),
                    value: '17',
                }, {
                    title: this.$t('edit.notify_at.21'),
                    value: '21',
                }
            ],
            diaryNotificationExtremeWordInput: false,
            diaryNotificationExtremeWordTimeInput: null,
            diaryNotificationExtremeWordTimeInputRules: [(v) => ((this.diaryNotificationExtremeWordInput && v == null) ? this.$t('general.msg.must_filled') : true)],
            diaryNotificationExtremeWordList: [
                {
                    title: this.$t('edit.notify_at.8'),
                    value: '8',
                }, {
                    title: this.$t('edit.notify_at.12'),
                    value: '12',
                }, {
                    title: this.$t('edit.notify_at.17'),
                    value: '17',
                }, {
                    title: this.$t('edit.notify_at.21'),
                    value: '21',
                }
            ],
            valid: false,
            diaryCalendarTypeInput: '0',
            diaryCalendarTypeList: [{
                title: this.$t('edit.calendar_mode.on'),
                value: '1',
            }, {
                title: this.$t('edit.calendar_mode.off'),
                value: '0',
            }],
            aiScoring: false,
            showQuestOnlyOptions: false,
            subType: [],
            shareTypeAvailable: true,
            currentPublishNumber: 0,
            currentTookNumber: 0,
            ucpCount: 0,
            defaultLang: 'zh',
        };
    },
    async created() {
        this.homeworkId = this.$route.params.id;
        if (localStorage.getItem('locale') != 'zh') {
            this.defaultLang = 'en';
        }
        if (typeof this.homeworkId == 'undefined') {    
            this.homeworkId = null;
            this.speechLangInput = this.defaultLang;
        } else {
            const diaryHomeworkDetailRes = await diaryServices.getHomeworkDetail(this.homeworkId, {});
            const diaryHomeworkDetailData = diaryHomeworkDetailRes.data;
            this.nameInput = diaryHomeworkDetailData.name;
            this.descriptionInput = diaryHomeworkDetailData.description;
            this.colorInput = diaryHomeworkDetailData.diary_cover_color;
            diaryHomeworkDetailData.user_classes.forEach(userClass => {
                this.gradeInput.push(userClass);
            });
            this.studentLimitInput = diaryHomeworkDetailData.diary_publish_num;
            this.takeEndDateInput = diaryHomeworkDetailData.diary_take_end_date;
            //this.colorList = diaryHomeworkDetailData.label_color;

            if (diaryHomeworkDetailData.notification_submit_time != null) {
                this.diaryNotificationSubmitInput = true;
                this.diaryNotificationSubmitTimeInput = diaryHomeworkDetailData.notification_submit_time;
            }
            if (diaryHomeworkDetailData.notification_stat_time != null) {
                this.diaryNotificationStatInput = true;
                this.diaryNotificationStatTimeInput = diaryHomeworkDetailData.notification_stat_time;
            }
            if (diaryHomeworkDetailData.notification_extreme_word_time != null) {
                this.diaryNotificationExtremeWordInput = true;
                this.diaryNotificationExtremeWordTimeInput = diaryHomeworkDetailData.notification_extreme_word_time;
            }

            if (!diaryHomeworkDetailData.speech_lang) {
                this.speechLangInput = this.defaultLang;
            } else {
                this.speechLangInput = diaryHomeworkDetailData.speech_lang;
            }
            if (diaryHomeworkDetailData.share_type == '5') {
                this.shareTypeAvailable = false;
            }
            this.diaryShareTypeInput = diaryHomeworkDetailData.share_type;
            if (diaryHomeworkDetailData.sub_type) {
                const types = JSON.parse(diaryHomeworkDetailData.sub_type);
                for (const subtype of types) {
                    switch (subtype) {
                        case 0:
                            this.diaryCalendarTypeInput = '1';
                            break;
                        case 2:
                            this.aiScoring = true;
                            break;
                    }
                }
                this.subType = types;
            }
            if (diaryHomeworkDetailData.share_type == '5') {
                this.showQuestOnlyOptions = true;
            }

            this.currentPublishNumber = diaryHomeworkDetailData.diary_publish_num;
            this.currentTookNumber = diaryHomeworkDetailData.diary_publish_num;
        }
        this.breadcrumbsItems.push(
            {
                text: this.$t('index.title'),
                disabled: false,
                href: `/diary`,
            });
        if (this.nameInput != '') {
            this.breadcrumbsItems.push({
                text: this.nameInput,
                disabled: false,
                href: `/diary/homework/${this.homeworkId}`,
            }, {
                text: this.$t('button.edit'),
                disabled: true,
                href: ``,
            })
        } else {
            this.breadcrumbsItems.push({
                text: this.$t('edit.diary_new'),
                disabled: true,
                href: ``,
            })
        }
        this.diaryTimeMinDate = new Date().toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
        this.ucpCount = await userPayServices.getUnsoldUserChargePlanCount();
    },
    mounted() {
    },
    computed: {

    },
    watch: {

    },
    methods: {
        showAlertMsg(type, msg) {
            this.snackBar.type = type;
            this.snackBar.text = msg;
            this.snackBar.on = true;
        },
        async saveHomework() {
            if (this.$refs.homeworkForm.validate()) {
                if (this.diaryCalendarTypeInput == '0') {
                    this.subType.forEach((item, index) => {
                        if (item == 0) {
                            this.subType.splice(index, 1);
                        }
                    });
                } else {
                    let isExist = false;
                    this.subType.forEach((item) => {
                        if (item == 0) {
                            isExist = true;
                        }
                    });
                    if (!isExist) {
                        this.subType.push(0);
                    }
                }

                const subTypeStr = JSON.stringify(this.subType);
                let payload = {
                    name: this.nameInput,
                    description: this.descriptionInput,
                    diary_cover_color: this.colorInput,
                    user_classes: this.gradeInput.join(','),
                    diary_publish_num: this.studentLimitInput,
                    diary_take_end_date: `${this.takeEndDateInput} ${this.takeEndTimeInput}`.trim(),
                    label_color: this.colorInput,
                    notification_submit_time: this.diaryNotificationSubmitInput ? this.diaryNotificationSubmitTimeInput : null,
                    notification_stat_time: this.diaryNotificationStatInput ? this.diaryNotificationStatTimeInput : null,
                    notification_extreme_word_time: this.diaryNotificationExtremeWordInput ? this.diaryNotificationExtremeWordTimeInput : null,
                    speech_lang: this.speechLangInput == 'user' ? '' : this.speechLangInput,
                    share_type: this.diaryShareTypeInput,
                    sub_type: subTypeStr,
                }
                const res = await diaryServices.createHomework(this.homeworkId, payload);
                this.$router.push('/diary/homework/:id/news'.replace(':id', res.data.id));
            } else {
                this.showAlertMsg('red', this.$t('general.msg.input_incomplete'))
            }
        }
    },
}
</script>
<style scoped>
.main-content {
    max-width: 650px;
}
</style>
