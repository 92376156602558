<template>
    <v-container fluid>
        <v-card outlined class="card-no-outline">
            <v-card-title class="text-h5 pa-0">{{ $t('quest.main.title') + title }}</v-card-title>
            <v-card-title>
                <v-row>
                    <span class="text-h6 grey--text text--darken-2">
                        {{ $t('quest.main.background') }}
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn small color="grey darken-2" class="normal-case"
                        dark @click="openDiarySetting">
                        <v-icon small>mdi-cog</v-icon>
                        <span class="d-none d-sm-block">{{ $t('info.button.diary_setting') }}</span>
                    </v-btn>
                </v-row>
            </v-card-title>
            <v-card outlined>
                <v-card-title>
                    <v-row>
                        <v-col cols="1" class="my-auto">
                            <v-icon x-large>mdi-book-open-variant</v-icon>
                        </v-col>
                        <v-col cols="11">
                            <v-row>
                                <v-card-title class="text-h6 font-weight-bold 
                                deep-orange--text text--darken-3 mb-0 pb-0">
                                    {{ title }}
                                    <v-btn class="mx-10 normal-case"
                                        outlined color="#25A2B7" small
                                        @click="checkInviteCode">
                                        {{ $t('quest.main.button_invitation_code') }}
                                    </v-btn>
                                </v-card-title>

                            </v-row>
                            <v-row>
                                <v-card-subtitle class="mt-0 pt-0">
                                    {{ description }}
                                </v-card-subtitle>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-card>
            <v-card outlined class="mt-2">
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="grey darken-2"
                        class="normal-case" dark @click="preTestLink">
                        <v-icon small class="mr-2">mdi-pencil</v-icon>
                        {{ $t('quest.main.button_pretest_link') }}
                    </v-btn>
                    <v-btn small color="grey darken-2"
                        dark @click="postTestLink" class="mr-2 normal-case">
                        <v-icon small>mdi-pencil</v-icon>
                        {{ $t('quest.main.button_posttest_link') }}
                    </v-btn>
                    <v-btn small color="grey darken-2" dark
                        class="mr-2 normal-case" @click="addQuestion(null)">
                        <v-icon small class="mr-2">mdi-plus</v-icon>
                        {{ $t('quest.main.button_add_question') }}
                    </v-btn>
                </v-card-actions>
                <v-card class="ma-2" outlined v-for="(item, index) in questions" :key="index">
                    <v-card-subtitle class="black--text">
                        <v-avatar
                            color="#2E3338"
                            size="20" class="my-auto mr-2 body-2 white--text">{{ `${index + 1}` }}</v-avatar>
                        {{ $t('quest.main.title_label') + item.title }}
                        <v-icon @click="addQuestion(item.id)" class="ml-10">mdi-pencil</v-icon>
                        <v-icon @click="openDeleteQuestionDialog(item.id)" color="red"
                            class="ml-2">mdi-delete</v-icon>
                    </v-card-subtitle>
                    <v-card-subtitle v-for="(sample, id) in item.contents"
                        :key="id" class="text-body-1 black--text ml-5">
                        <v-chip disable :ripple="false" class="mr-2">
                            {{ $t(`quest.main.${sample.type}_frame`) }}
                        </v-chip>
                        {{ sample.title }}
                    </v-card-subtitle>
                </v-card>
            </v-card>
        </v-card>
        <StudentInvitationDialog v-model="inviteCodeDialog" :title="title" :isSample="true"></StudentInvitationDialog>
        <v-dialog v-model="deleteQuestionDialog" persistent width="600">
            <v-card class="pb-2">
                <v-card-title>
                    {{ $t('quest.main.deleteDialog.title') }}
                </v-card-title>
                <v-card-subtitle class="text-center text-h6 mt-2">
                    {{ $t('quest.main.deleteDialog.message') }}
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-2" class="normal-case"
                        text @click="deleteQuestionDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="error" class="normal-case"
                        @click="deleteQuestion" :loading="loading">
                        {{ $t('button.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { diaryServices } from "@/services/diary.js";
import StudentInvitationDialog from '@/components/console/diary/component/StudentInvitationDialog.vue';

export default {
    name: 'Quest',
    components: { StudentInvitationDialog },
    data() {
        return {
            homeworkId: null,
            title: '',
            description: '',
            questions: [],
            inviteCode: '',
            inviteCodeDialog: false,
            deleteQuestionDialog: false,
            idToDelete: null,
            loading: false,
            lang: '',
        }
    },
    async created() {
        this.homeworkId = this.$route.params.id;
        const result = await diaryServices.getHomeworkDetail(this.homeworkId);
        if (result.success) {
            this.title = result.data.name;
            this.description = result.data.description;
            this.lang = result.data.speech_lang;
        }
        const questionResult = await diaryServices.getSampleQuest(this.homeworkId);
        if (questionResult.success) {
            this.questions = questionResult.data.list;
        }
    },
    methods: {
        openDiarySetting() {
            // this.$router.push(`/diary/homework/${this.homeworkId}/edit`);
            this.$router.push(`/diarySample/${this.homeworkId}/edit`)
        },
        preTestLink() {
            console.log('preTestLink');
        },
        postTestLink() {
            console.log('postTestLink');
        },
        addQuestion(id) {
            if (id) {
                this.$router.push(`/diary/homework/${this.homeworkId}/quest/edit?questId=${id}&lang=${this.lang}`);
            } else {
                this.$router.push(`/diary/homework/${this.homeworkId}/quest/edit?lang=${this.lang}`);
            }
        },
        async checkInviteCode() {
            this.inviteCodeDialog = true;
        },
        openDeleteQuestionDialog(id) {
            this.idToDelete = id;
            this.deleteQuestionDialog = true;
        },
        async deleteQuestion() {
            this.loading = true;
            const id = this.idToDelete;
            const result = await diaryServices.deleteSampleQuest(this.homeworkId,
                id);

            if (result.data.return_code == '06000') {
                this.questions = this.questions.filter(item => item.id != id);
            }
            this.loading = false;
            this.deleteQuestionDialog = false;
        },
    },
    computed: {

    },
}
</script>
<style scoped>
.card-no-outline {
    border: none;
}
</style>