<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="12" md="12" class="pa-1">
                <v-card outlined>
                    <v-card-title>
                        <v-icon x-large>mdi-book-open-variant</v-icon>
                        <div class="ml-2">{{ title }}</div>
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <v-card-actions v-if="!isJoinedHomework">
                        <v-btn v-if="!mapLink" small outlined color="cyan darken-2" @click="openStudentInvitationDialog = true"
                            class="normal-case">{{
                            $t('info.button.invite_student') }}</v-btn>
                        <v-btn v-if="mapLink" small outlined color="cyan darken-2" @click="openMapLinkDialog = true"
                            class="normal-case">{{
                            $t('info.button.student_link') }}</v-btn>
                        <v-btn small outlined color="cyan darken-2" @click="openTeacherInvitationDialog = true"
                            class="ml-4 normal-case">{{ $t('info.button.invite_teacher') }}</v-btn>
                        <v-menu
                            :close-on-content-click="false"
                            offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text color="grey darken-2"
                                    class="text-decoration-underline normal-case"
                                    v-bind="attrs"
                                    v-on="on"
                                    :ref="privilegeTeachers">
                                    {{ formatTeachers() }}
                                </v-btn>
                            </template>
                            <span>
                                <v-list>
                                    <v-list-item class="mx-3" v-if="privilegeTeachers.length == 0">
                                        {{ $t('info.diary.no_teacher') }}
                                    </v-list-item>
                                    <v-list-item v-for="teacher in privilegeTeachers"
                                        :key="teacher.id">
                                        <v-list-item-avatar>
                                            <v-img
                                                class="role-thumbnail"
                                                :src="teacher.avatar_url"></v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content class="teacher-name">
                                            <v-list-item-title v-text="teacher.name"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <span v-if="teacher.status == 0">
                                                <v-btn small color="success"
                                                    class="mx-1 normal-case"
                                                    @click="acceptPrivilegeTeacher(teacher)">
                                                    {{ $t('info.diary.accept') }}
                                                </v-btn>
                                                <v-btn small outlined color="error"
                                                    class="mx-1 normal-case"
                                                    @click="rejectPrivilegeTeacher(teacher)">
                                                    {{ $t('info.diary.reject') }}
                                                </v-btn>
                                            </span>
                                            <span v-if="teacher.status == 1">
                                                <v-btn small outlined color="error"
                                                    class="mx-1 normal-case"
                                                    @click="openRemovePrivilegeDialog(teacher)">
                                                    {{ $t('info.diary.remove') }}
                                                </v-btn>
                                            </span>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </span>
                        </v-menu>
                        <v-spacer></v-spacer>
                        <v-btn small color="grey darken-2" dark @click="openDiarySetting"
                            class="normal-case">
                            <v-icon small>mdi-cog</v-icon>
                            <span class="d-none d-sm-block">{{ $t('info.button.diary_setting') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12" class="pa-1">
                <v-card outlined>
                    <v-card-title class="subtitle-1 py-2">
                        {{ $t('general.diary.description') }}
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-subtitle>
                        {{ description }}
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="6" class="pa-1">
                <v-card outlined>
                    <v-card-title class="subtitle-1 py-2">
                        {{ $t('general.diary.overview') }}
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-subtitle>{{ $t('general.diary.recent_submit') }}</v-card-subtitle>
                    <div v-if="recentPostUserList.length == 0" class="text-center pb-3">{{ $t('general.msg.no_data') }}
                    </div>
                    <v-row class="no-gutters">
                        <v-col class="cursor-pointer text-center mb-2" v-for="(item, i) in recentPostUserList" :key="i"
                            cols="3" sm="2" md="4" lg="3" @click="toStudentPage(item.creator_id)">
                            <v-badge :content="item.count" color="grey" offset-x="10" offset-y="10">
                                <v-avatar color="grey lighten-2">
                                    <img :src="item.avatar_url" :alt="item.user_name">
                                </v-avatar>
                            </v-badge>
                            <v-card-subtitle class="pa-0">{{ item.user_name }}</v-card-subtitle>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-1">
                <BadMood></BadMood>
            </v-col>
        </v-row>
        <TeacherInvitationDialog v-model="openTeacherInvitationDialog"></TeacherInvitationDialog>
        <StudentInvitationDialog v-model="openStudentInvitationDialog"></StudentInvitationDialog>
        <MapLinkDialog v-model="openMapLinkDialog" :mapLink="mapLink"></MapLinkDialog>

        <v-dialog v-model="removePrivilegeTeacherDialog" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('info.remove_dialog.title') }}
                </v-card-title>
                <v-card-text class="text-h6 text-center">
                    {{ formatDialogText() }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn plain class="mb-2 normal-case"
                        @click="removePrivilegeTeacherDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="error" class="mb-2 normal-case" :loading="loading"
                        @click="removePrivilegeTeacher">
                        {{ $t('button.confirm') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { diaryServices } from "@/services/diary.js";
import { diaryPostServices } from "@/services/diaryPost.js";
import { contestServices } from "@/services/contest.js";

import TeacherInvitationDialog from '@/components/console/diary/component/TeacherInvitationDialog.vue';
import StudentInvitationDialog from '@/components/console/diary/component/StudentInvitationDialog.vue';
import MapLinkDialog from "@/components/console/diary/component/MapLinkDialog.vue";
import BadMood from '@/components/console/diary/component/BadMood.vue';
export default {
    name: 'diaryInfo',
    components: { TeacherInvitationDialog, StudentInvitationDialog, BadMood, MapLinkDialog },
    data() {
        return {
            homeworkId: '',
            title: '',
            teamMemberCount: 0,
            openTeacherInvitationDialog: false,
            openStudentInvitationDialog: false,
            buttonLoading: false,
            email: '',
            emailRules: [(v) => !!v || this.$t('info.email_rule'),
            (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('info.email_incorrect')],
            description: '',
            sendingMail: false,
            mailSend: false,
            recentPostUserList: [],
            privilegeTeachers: [],
            removePrivilegeTeacherDialog: false,
            teacherToRemove: '',
            loading: false,
            diaryType: '',
            isContestHomework: false,
            isJoinedHomework: true,
            mapLink: '',
            openMapLinkDialog: false,
        }
    },
    async created() {
        this.homeworkId = this.$route.params.id;

        //goto contest info page if the homework is contest homework
        const contestRes = await contestServices.checkHomeworkContest(this.homeworkId);
        if (contestRes.data.is_contest_home_work) {
            this.$router.push(`/diary/homework/${this.homeworkId}/contestInfo`);
        }
        const result = await diaryServices.getHomeworkDetail(this.homeworkId);
        if (result.success) {
            this.title = result.data.name;
            this.description = result.data.description;
            this.diaryType = result.data.share_type;
            if (result.data.map_link) {
                this.mapLink = result.data.map_link;
            }
        }
        const joinedHomeworkCheck = await diaryServices.checkJoinedHomework(this.homeworkId);
        this.isJoinedHomework = joinedHomeworkCheck.data.is_joined;
        const recentRes = await diaryPostServices.getHomeworkRecentPostUser(this.homeworkId, {});
        recentRes.data.forEach(item => {
            this.recentPostUserList.push(item);
        });
        await this.getPrivilegeTeachers();
    },
    methods: {
        openDiarySetting() {
            if (this.isContestHomework) {
                this.$router.push(`/contest/${this.homeworkId}/edit?new=-1`);
            } else {
                this.$router.push(`/diary/homework/${this.homeworkId}/edit`);
            }
        },
        formatTeachers() {
            return this.$t('info.diary.teacher_count')
                .replace('${num}', this.privilegeTeachers.length);
        },
        async getPrivilegeTeachers() {
            const teachers = await diaryServices.getHomeworkTeachers(this.homeworkId);
            this.privilegeTeachers = teachers.data.teachers;
        },
        async acceptPrivilegeTeacher(teacher) {
            console.log('acceptPrivilegeTeacher teacher', teacher)
            const payload = {
                user_id: teacher.id,
            }
            const result = await diaryServices.acceptHomeworkPrivilegeUser(this.homeworkId, payload);
            if (result.success) {
                this.privilegeTeachers.find(item => item.id == teacher.id).status = 1;
            }
        },
        async rejectPrivilegeTeacher(teacher) {
            const payload = {
                user_id: teacher.id,
            }
            const result = await diaryServices.rejectHomeworkPrivilegeUser(this.homeworkId, payload);
            if (result.success) {
                this.privilegeTeachers = this.privilegeTeachers.filter(item => item.id != teacher.id);
            }
        },
        openRemovePrivilegeDialog(teacher) {
            this.teacherToRemove = teacher;
            this.removePrivilegeTeacherDialog = true;
        },
        async removePrivilegeTeacher() {
            this.loading = true;
            const payload = {
                user_id: this.teacherToRemove.id,
            }
            const result = await diaryServices.deleteHomeworkPrivilegeUser(this.homeworkId, payload);
            if (result.success) {
                this.privilegeTeachers = this.privilegeTeachers.filter(item => item.id != this.teacherToRemove.id);
            }
            this.loading = false;
            this.removePrivilegeTeacherDialog = false;
        },
        formatDialogText() {
            return this.$t('info.remove_dialog.text')
                .replace('${name}', this.teacherToRemove.name);
        },
        toStudentPage(studentId) {
            this.$router.push(`/diary/homework/${this.homeworkId}/student/${studentId}`);
        },
    },
}
</script>

<style scoped>
.role-thumbnail {
    width: 50px;
    align-items: center;
}

.teacher-name {
    width: 150px;
}
</style>
